<template>
  <section id="main-view">
    <div id="main-view__wrap">
      <div id="main-view__contact">
        <div id="main-view__logo">
          <img src="../../assets/lp_new/main_logo.png" alt="logo" />
        </div>
        <div id="main-view__text">
          <span>Your Change Your Power</span>
        </div>
      </div>
      <div id="main-view__server"></div>
    </div>
    <div id="main-view__light"></div>
  </section>
</template>

<script>
export default {};
</script>

<style>
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
/* main-view */
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
#main-view {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

#main-view__wrap {
  position: absolute;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  width: 100%;
  height: 100vh;
  max-width: 1020px;
  padding-top: 100px;
}

#main-view__server {
  width: 832px;
  height: 744px;
  background-image: url("../../assets/lp_new/main_server.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
  flex-shrink: 0;
  margin-left: -121px;
}

#main-view__light {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-image: url("../../assets/lp_new/main_light.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 0;
}

#main-view__contact {
  z-index: 2;
  margin-top: -130px;
}

#main-view__logo > img {
  margin-left: auto;
  margin-right: auto;
  width: 436px;
}

#main-view__text {
  overflow: visible;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 30px auto;
}

.mail_icon {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/mail_icon.png");
  width: 23px;
  height: 15px;
  display: block;
  margin-right: 10px;
}

@media screen and (max-width: 1020px) {
  #main-view {
    min-height: 600px;
    max-height: 800px;
  }

  #main-view__wrap {
    max-width: 700px;
    height: auto;
    padding-top: 50px;
  }

  #main-view__logo {
    width: 50%;
  }

  #main-view__logo > img {
    width: 380px;
  }

  #main-view__server {
    right: 0px;
    top: 90px;
    width:  480px;
    height: 410px;
  }

  #main-view__light {
    max-height: 500px;
  }
}

@media screen and (max-width: 768px) {
  #main-view {
    height: 80vh;
    min-height: 690px;
  }

  #main-view__wrap {
    padding-top: 0px;
    min-height: 690px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: end;
    overflow: hidden;
  }

  #main-view__contact {
    position: initial;
    padding: 15px 30px;
  }

  #main-view__logo > img {
    width: 280px;
  }

  #main-view__text {
    font-size: 14px;
  }

  #main-view__server {
    width: 345px;
    height: 320px;
    margin-left: 0;
    background-image: url("../../assets/lp_new/main_server_sp.png");
  }
  #main-view__light {
    width: 80%;
    height: 60%;
  }
}
</style>
